<template>
    <sv-card title="历史交流">
        <div slot="body" class="chat-wrap">
            <!-- 内容框 -->
            <div class="content-wrap" v-viewer>
                <div v-for="(item, idx) in chatItmes" :key="idx">
                    <div class="item-wrap">
                        <div class="right">
                            <div class="header">
                                <span class="send-name">回复人：{{ item.createdBy }} </span>
                                <span class="send-time">{{ item.createdOn }}</span>
                            </div>
                            <div class="content" v-html="item.content"></div>

                            <div class="attach-wrap">
                                <span class="file">附件：</span>
                                <div class="attach-item" v-for="i in item.resList" :key="i.id">
                                    <span class="attach-download"><a :href="i.path" target="_blank">下载</a></span>
                                    <span class="attach-icon">
                                        <span class="icon iconfont icon-link"></span>
                                    </span>
                                    <span class="attach-name">{{ i.name }} <span class="attach-size">({{
                                        getSize(i.bytes)
                                    }})</span></span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 无记录 -->
                <div class="no-record" v-if="chatItmes.length === 0">无记录</div>
            </div>

            <!-- 聊天框 -->
            <Form :model="form" label-position="left" :rules="ruleValidate" :label-width="100">
                <FormItem label="主题交流：" prop="input">
                    <Input v-model="form.input" type="textarea"></Input>
                </FormItem>

                <FormItem label="上传附件：">
                    <addUpload
                        btnUplodText="浏览"
                        class="btn-upload"
                        :url="url"
                        :formDynamic="formDynamic"
                        @handleUpload="handleUpload"
                        @handleAdd="handleAdd(formDynamic)">
                    </addUpload>
                </FormItem>
            </Form>
            <Button v-if="btnDisabled" type="primary" class="btn" @click="doReply"
                v-permission="'tism:consult-view:detail-reply'">发送</Button>

            <!-- showLoading -->
            <Spin size="large" v-if="showLoading" fix></Spin>
        </div>
    </sv-card>
</template>

<script>
import Vue from 'vue';
import { request } from '@/network/request';
import VueViewer from 'v-viewer';
import addUpload from './add-upload.vue';

VueViewer.setDefaults({
    toolbar: {
        prev: true,
        next: true,
        rotateLeft: true,
        rotateRight: true,
        flipHorizontal: true,
        flipVertical: true
    },
    loop: false,
    title: false
});
Vue.use(VueViewer);

export default {
    components: {
        addUpload
    },
    data() {
        return {
            editorData: null,
            chatItmes: [],
            showLoading: false,
            loadingStatus: false,
            data: {},
            fileData: '',
            fileData1: [],
            form: {
                input: '',
                fileList: ''
            },
            formDynamic: [
                {
                    value: '',
                    index: 1
                }
            ],
            index: 1,
            lists: [],
            resIds: [],
            params: {},
            ruleValidate: {
                input: [
                    {
                        required: true,
                        message: '请添加回复内容',
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    mounted() {
        this.loadChatContent();
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        loadUrl: {
            type: String
        },
        submitUrl: {
            type: String
        },
        url: {
            type: String,
            default: ''
        },
        btnDisabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        /**
         * 回复
         */
        doReply() {
            const url = this.submitUrl;
            const params = this.getParams();

            this.showLoading = true;

            request
                .post(url, params)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('回复成功');
                        this.loadChatContent();
                        this.form.input = '';
                        this.resIds = [];
                    } else {
                        this.$Message.error('加载失败: ' + data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },

        /**
         * 获取参数
         */
        getParams() {
            const id = this.$route.query.id;
            this.editorData = this.form.input;

            return {
                engineeringConsultId: id,
                content: this.editorData,
                resIds: this.resIds
            };
        },

        getSize(size) {
            let unit;
            let units = ['B', 'K', 'M', 'G', 'TB'];
            while ((unit = units.shift()) && size > 1024) {
                size = size / 1024;
            }
            return (unit === 'B' ? size : size.toFixed(2)) + unit;
        },

        /**
         * 加载对话内容
         */
        loadChatContent() {
            const url = this.loadUrl;

            this.showLoading = true;

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        this.chatItmes = data.result || [];
                    } else {
                        this.$Message.error('加载失败: ' + data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },

        /**
         * 处理上传附件
         */
        handleUpload(res) {
            this.resIds = res;
        },

        handleAdd(list) {
            if (list.slice(-1)[0].value === '') {
                this.$Message.success('请先上传附件');
                return;
            }

            this.index++;
            if (list.length < 5) {
                list.push({
                    value: '',
                    index: this.index
                });
            } else {
                this.$Message.error('最多只能添加5个附件');
            }
        }
    }
};
</script>

<style lang="less" scoped>
/deep/.ivu-card-body {
    padding: 0;
}

.chat-wrap {
    display: flex;
    flex-direction: column;
    position: relative;

    .content-wrap {
        padding: 5px;

        .item-wrap {
            display: flex;
            flex-direction: row;

            &.flex-end {
                justify-content: end;
            }

            .left {
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
                background: #ebf4ff;
                border-radius: 5px;
                width: 50%;

                .header {
                    padding: 8px 5px;
                    border-bottom: 1px solid #d4e4f6;
                }

                .content {
                    padding: 5px 15px;

                    .send-name {
                        font-size: 12px;
                        color: #646e7a;
                        font-weight: 600;
                        margin: 5px;
                    }

                    .send-time {
                        font-size: 10px;
                        color: #aeb3b8;
                        font-weight: 400;
                        margin-right: 5px;
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                border-bottom: 1px #d4e4f6 solid;

                width: 100%;

                .header {
                    display: flex;
                    justify-content: space-between;
                    padding: 0px 0px 5px 15px;
                    width: 100%;
                }

                .content {
                    padding: 5px 15px;
                    text-align: left;

                    .send-name {
                        font-size: 12px;
                        color: #646e7a;
                        font-weight: 600;
                        margin: 5px;
                    }

                    .send-time {
                        font-size: 10px;
                        color: #aeb3b8;
                        font-weight: 400;
                        margin-right: 5px;
                    }
                }

                .attach-wrap {
                    flex-wrap: wrap;
                    padding: 5px 0px;
                    margin-left: 10px;

                    .file {
                        margin-left: 5px;
                    }

                    .attach-item {
                        display: flex;
                        align-items: center;
                        margin-top: 5px;
                        margin-right: 10px;

                        .attach-icon {
                            margin-right: 5px;
                            color: #4a8deb;
                        }

                        .attach-name {
                            color: #333;
                            font-size: 14px;

                            .attach-size {
                                color: #999;
                                font-size: 12px;
                                margin-left: 5px;
                            }
                        }

                        .attach-download {
                            color: #4a8deb;
                            border: 1px solid #4a8deb;
                            margin-left: 10px;

                            a {
                                padding: 0 5px;
                                color: #4a8deb;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .ivu-form {
        padding: 0 30px;

        .ivu-form-item {
            margin-bottom: 10px;
        }
    }

    .btns-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;

        /deep/ .ivu-btn-small {
            height: 28px;
            padding: 0px 16px;
        }
    }
}

.btn {
    width: 65px;
    margin: 0 auto;
    margin-bottom: 5px;
}
</style>
