<template>
    <div class="page-wrap">
        <div class="header">
            <div>
                <span>{{ title }}</span>
                <span>处理状态： {{ baseData.replyStatusName }}</span>
                <span> 是否关闭： {{ baseData.closeStatusName }} </span>
            </div>
        </div>

        <div class="content">
            <!-- 基本信息 -->
            <sv-card title="问题" :showLoading="showLoading">
                <div slot="body" class="body">
                    <sv-panel-form ref="baseForm" :labelWidth="120" :isShowFooter="false">
                        <item type="row">
                            <item type="col">
                                <item label="工程咨询单号" type="displayfield" name="id" />
                                <item label="工程咨询时间" type="displayfield" name="createdOn" />
                                <item label="品牌" type="displayfield" name="brandName" />
                                <item label="车系" type="displayfield" name="seriesName" />
                            </item>
                            <item type="col">
                                <item label="提问人" type="displayfield" name="questionMan" />
                                <item label="提问手机号" type="displayfield" name="questionMobile" />
                                <item label="服务站代码" type="displayfield" name="dealerCode" />
                                <item label="服务站名称" type="displayfield" name="dealerName" />
                            </item>
                            <item type="col">
                                <item label="零件号" type="displayfield" name="partCode" />
                                <item label="零件名称" type="displayfield" name="partName" />
                            </item>
                            <item type="col">
                                <item label="手册分类" type="displayfield" name="manualType" />
                                <item label="手册章节" type="displayfield" name="manualChapter" />
                                <item label="手册页码" type="displayfield" name="manualPage" />
                            </item>
                            <item type="col">
                                <item label="问题描述" type="displayfield" name="questionDesc" span="24" />
                            </item>
                            <item type="col">
                                <item label="附件" type="images" name="resList" span="24" />
                            </item>
                        </item>

                        <template #resList="data">
                            <!-- 7.图片 -->
                            <div style="display:flex">
                                <myImage
                                    v-for="item in data.data"
                                    :item="item"
                                    :key="item.id"
                                    :width="80"
                                    :height="80"
                                    :src="item.path"
                                    :extension="item.extension"
                                    :isShowDownload="false">
                                </myImage>
                            </div>
                        </template>
                    </sv-panel-form>

                    <div class="btnField">
                        <Button
                            class="btn"
                            type="primary"
                            @click="doClaim"
                            v-if="!baseData.claimBy && baseData.closeStatus == '0'">认领</Button>
                        <Button
                            class="btn"
                            type="primary"
                            @click="doClose"
                            v-if="baseData.claimBy && baseData.closeStatus == '0'"
                            v-permission="'tism:consult-view:detail-close'">关闭</Button>
                    </div>
                </div>
            </sv-card>

            <!-- 沟通记录 -->
            <ChatInfo
                :isEdit="true"
                :btnDisabled="baseData.claimBy && baseData.closeStatus == '0'"
                @submited="loadBaseData"
                :loadUrl="`/consult/engineering/consult/chat/history?id=${$route.query.id}`"
                submitUrl="/consult/engineering/consult/chat/reply"
                url="/api/consult/res/upload" />
        </div>
    </div>
</template>

<script>
import { request } from '@/network/request';
import myImage from '../common/my-image.vue';
import ChatInfo from '../common/chatInfo.vue';

export default {
    name: 'M001-1',
    components: {
        ChatInfo, myImage
    },
    data() {
        return {
            title: document.title,
            baseData: {},
            showLoading: false,
            resIds: [],
            params: {}
        };
    },

    computed: {},

    created() {
        this.loadBaseData();
    },

    methods: {
        loadBaseData() {
            const url = '/consult/engineering/consult/detail?id=' + this.$route.query.id;
            this.showLoading = true;

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        const { result } = data;
                        this.baseData = result || {};
                        this.$nextTick(() => {
                            this.$refs.baseForm.setValues(result);
                        });
                    } else {
                        this.$Message.error(data.message);
                    }
                })

                .finally(() => {
                    this.showLoading = false;
                });
        },

        handleUpload(res, params) {
            this.resIds = res;
            this.params = params;
        },

        doClaim() {
            const url = '/consult/engineering/consult/claim';
            const params = {
                id: this.$route.query.id
            };

            this.showLoading = true;

            request
                .post(url, params)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('认领成功');
                        this.loadBaseData();
                    } else {
                        this.$Message.error(data.message);
                    }
                })

                .finally(() => {
                    this.showLoading = false;
                });
        },
        doClose() {
            const url = '/consult/engineering/consult/close';
            const params = {
                id: this.$route.query.id
            };

            this.showLoading = true;

            request
                .post(url, params)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('关闭成功');
                        this.loadBaseData();
                    } else {
                        this.$Message.error(data.message);
                    }
                })

                .finally(() => {
                    this.showLoading = false;
                });
        }
    }
};
</script>

<style lang="less" scoped>
.page-wrap {
    height: 100%;
    margin: 0 auto;
    width: 70%;
    min-width: 1100px;
    display: flex;
    flex-direction: column;
    position: relative;

    .header {
        display: flex;
        justify-content: space-between;
        position: relative;
        font-size: 14px;
        color: #4e4e4e;
        height: 34px;
        line-height: 34px;
        padding: 0 6px;
        font-weight: 600;
        background: #e4e4e4;

        span:nth-child(2) {
            position: absolute;
            right: 200px;
            top: 0;
        }

        span:last-child {
            position: absolute;
            right: 20px;
            top: 0;
        }
    }

    .content {
        width: 100%;
        flex: 1;
        padding: 5px 0;

        .form-footer {
            text-align: center;
            padding: 10px 0;
        }
    }

    /deep/ .ivu-card-head {
        background: #e4e4e4;
    }

    /deep/.displayfield {
        margin-top: 4px;
    }

    /deep/.images-wrap {
        margin-right: 10px;
    }

    /deep/ .image {
        height: 100%;
    }

    /deep/ .ivu-form-item {
        height: 100%;
    }

    .btnField {
        width: 100%;
        display: flex;

        .btn {
            margin: 0 auto;
        }
    }
}
</style>
