<template>
    <div>
        <div v-if="extension === 'png' || extension === 'jpg'">
            <div class="images-wrap" :style="style" v-viewer>
                <img ref="img" :src="imgObject.thumbnail" :data-src="imgObject.source"
                    :style="imageStyle" />
                <div class="item" :title="item.name + '.' + item.extension">
                    <div class="name">{{ item.name }}</div>
                    <div>{{ '.'+ item.extension }}</div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="images-wrap" :style="style" @click="openFile">
                <img ref="img" :src="imgObject.thumbnail"
                    v-defaultImg="file" />
                <div class="item">
                    <div class="name">{{ item.name }}</div>
                    <div>{{ '.'+ item.extension }}</div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import file from '@/assets/images/file.png';
import nopic from '@/assets/images/nopic.png';

export default {
    props: {
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        prefixSrc: {
            type: String,
            default: ''
        },
        src: {
            type: String,
            default: ''
        },
        errorSrc: {
            type: String,
            default: ''
        },
        isShowDownload: {
            type: Boolean,
            default: () => true
        },
        extension: {
            type: String,
        },
        item: {
            type: Object,
        }
    },

    data() {
        return {
            imageSrc: '',
            imageStyle: {
                width: '100%',
                height: '100%'
            },
            file: file,
            nopic: nopic
        };
    },

    computed: {
        style() {
            if (this.width && this.height) {
                return 'width:' + this.width + 'px;height:' + this.height + 'px';
            } else {
                return 'height: 100%; width: 100%;';
            }
        },

        imgObject() {
            if (this.width && this.height) {
                return {
                    thumbnail: this.imageSrc + `?x-oss-process=image/resize,m_fill,h_${this.height},w_${this.width}`,
                    source: this.imageSrc
                };
            } else {
                return {
                    thumbnail: this.imageSrc,
                    source: this.imageSrc
                };
            }
        }
    },

    watch: {
        src() {
            this.loadImage();
        }
    },

    mounted() {
        this.loadImage();
    },

    methods: {
        loadImage: function () {
            const self = this;
            const img = new Image();

            img.onload = function () {
                self.setImgSize(this);
            };

            img.onerror = function () {
                let errImg = new Image();
                let src = self.errorSrc;

                errImg.onload = function () {
                    self.setImgSize(this, src);
                };

                errImg.src = src;
            };

            img.src = this.prefixSrc + this.src;
        },

        setImgSize(img) {
            const image = this.$refs.img;
            const outerWidth = image.parentElement.clientWidth;
            const outerHeight = image.parentElement.clientHeight;
            const imgWidth = img.width;
            const imgHeihgt = img.height;
            const size = this.scalingDownCalc(imgWidth, imgHeihgt, outerWidth, outerHeight);
            const imageStyle = {
                width: size.width + 'px',
                height: size.height + 'px',
                top: size.top + 'px',
                left: size.left + 'px'
            };

            this.imageSrc = img.src;
            this.imageStyle = imageStyle;
        },

        scalingDownCalc: function (iW, iH, mW, mH) {
            let newW = 0;
            let newH = 0;
            let top = 0;
            let left = 0;

            if (iH / iW >= mH / mW) {
                if (iH > mH) {
                    newH = mH;
                    newW = (iW * mH) / iH;
                } else {
                    newW = iW;
                    newH = iH;
                }
            } else {
                if (iW > mW) {
                    newW = mW;
                    newH = (iH * mW) / iW;
                } else {
                    newW = iW;
                    newH = iH;
                }
            }

            // newH = 'auto';
            // newW = '100%';
            // newH = newH - 20;
            // newW = newW - 20;
            // newH = newH - 10;
            // newW = newW - 10;

            top = (mH - newH) / 2;
            left = (mW - newW) / 2;

            return {
                width: newW,
                height: newH,
                top: top,
                left: left
            };
        },

        openFile() {
            if (this.extension === 'pdf') {
                window.open(this.src);
            }
            if (this.extension === 'xls' || this.extension === 'xlsx') {
                window.open(this.src);
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.images-wrap {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    margin-right: 20px;
    border: 1px solid #ccc;
    box-shadow: 3px 3px 3px #888888;
    // overflow: hidden;
    height: 100%;
    width: 100%;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    .download {
        position: absolute;
        text-align: center;
        top: 0;
        right: 0;
        width: 23px;
        height: 23px;
        z-index: 999;
        background-color: #1890ff;
        color: #fff;
        line-height: 18px;
        border-radius: 3px;
        cursor: pointer;
    }

    .item {
        margin-top: 82px;
        display: flex;

        .name {
            max-width: 55px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap
        }
    }


}
</style>
